import React, { useState, useEffect } from "react";
import { gsap } from "gsap";

import "./styles.css";

import Landing from "./Routes/Landing";

//data
import content from "./Data/content";
import gridContext from "./Context/Grid";

export default function App() {
  const [gridOpen, setGridOpen] = useState(true);
  const [activeTile, setActiveTile] = useState(null);
  const [panelContent, setPanelContent] = useState();
  const ctx = gsap.context(() => {});

  return (
    <div className="App">
      <gridContext.Provider 
          value={{
            gridOpen,
            setGridOpen,
            activeTile,
            setActiveTile,
            panelContent,
            setPanelContent,
            ctx
          }}
      >
        <Landing content={content} />
      </gridContext.Provider>
      
    </div>
  );
}
