const content = [
  {
    id: "grid-image-1",
    classNames: "image-vertical",
    title: "ABOUT",
    imgURL: "/images/beach1.JPG",

    items: [
        {
            about: true
        }
    ]
  },
  {
    id: "grid-image-2",
    classNames: "image-square",
    title: "RESUME",
    imgURL: "/images/beach1.JPG",
    items: [
      {
        title: "Work Experience"
      },
      {
        subtitle: "Habesha Film Association, Freelance Web Developer",
        paragraph:
          "Transformed client needs into production-ready applications. Developed a membership web app with user account and profile managment tools, a job board, and a paywall for member exclusive contents."
      },
      {
        subtitle: "Space Telescope Science Institute, Front-end Developer",
        paragraph:
          "worked with a team of science curators and web designers to maintain the institute's external facing web applications."
      },
      {
        subtitle: "University of Baltimore, Computer Science Professor",
        paragraph:
          "Crafted curriculum and lesson content, ensuring that students meet skill targets. Delivered lectures and provided academic mentorship."
      },
      {
        subtitle: "Code in the Schools, Instructor",
        paragraph:
          "Summer instructor at Code-Works, a 5-week intensive program. Taught Python and Django REST framework to high school students."
      },
      {
        title: "Technical Skills",
        ul: [
          "Backend: Django, SQL, PostgreSQL",
          "Frontend: React",
          "Others: Linux, LabVIEW",
          "Languages: Python, JavaScript",
          "Certifications: Currently pursuing certification in Amazon Web Services."
        ]
      },
      {
        title: "Education & Training"
      },
      {
        subtitle: "Morgan State University - 2022",
        ul: [
          "Degree: MSc in Integrated Science",
          "Highlights: Research Assistant for Dr. Dereje Seifu, characterized nanomaterials and contributed to published research."
        ]
      },
      {
        subtitle: "University of Baltimore - 2018",
        ul: ["Degree: BSc in Applied IT"]
      },
      {
        title: "Soft Skills",
        ul: [
          "Strategic thinker, excelling in efficiency and adaptability.",
          "Natural leader, passionate about team synergy.",
          "Committed to increasing diversity in the tech industry."
        ]
      },
      {
        title: "References can be provided upon request"
      }
    ]
  },
  {
    id: "grid-image-3",
    classNames: "image-horizontal",
    title: "PROJECTS",
    imgURL: "/images/beach1.JPG",
    items: [
      {
        title: "Habesha Film Association",
        subtitle:
          "A Website to bridge the gab between film industry profetionals in the Horn of Africa with the rest of the world",
        img: {
            classNames: "details--item--img",
            url: "https://hfa.media/media/main/img/logo_main.png"
        },
        links: [
          {
            href: "https://hfa.media/",
            text: "Live Site"
          }
        ],
        paragraph:
          "I created a web application fully built with django to help the association connect with its members, collect donations, and publish offical documments. I also created a custom user account managment system and a paywall for exclusive member content. I worked with the director of communications and other members of the leadership team to plan, design, and create the web application."
      },
      {
        title: "Encanto Oculto",
        subtitle: "A Simple Blog with Django Backend and React Frontend",
        img:{
            classNames: "details--item--img",
            url: "https://eo-backend-7a2f14a16898.herokuapp.com/media/main/post/bee_and_key.jpeg"
        },
        links: [
          {
            href: "https://eo-frontend-a1e439a3effc.herokuapp.com/",
            text: "Demo"
          },
          {
            href: "https://github.com/brookdb/EOB_backend",
            text: "Backend Code"
          },
          {
            href: "https://github.com/brookdb/EOB_frontend",
            text: "Frontend Code"
          }
        ],
        paragraph:
          "I created a dynamic and engaging blog that seamlessly integrates Django as the backend and React as the frontend. This project showcases my proficiency in using Django Rest Framework as well as extending the library to create a custom backend protocol (custom user model). To enhance user experience, I leveraged Axios to efficiently retrieve data from the API to the frontend. Additionally, I implemented React Context for effective styling and made use of the versatile React Bootstrap library for creating various components. This project not only demonstrates my technical skills but also highlights my ability to deliver a user-friendly and aesthetically pleasing web application."
      },
      {
        title: "Ballet Vinyls",
        img: {
            classNames: "details--item--img",
            url: "/images/bv.png"
        },
        links: [
          {
            href: "https://ballet-vinyl-107d7e609aa3.herokuapp.com/",
            text: "Demo"
          },
          {
            href: "https://github.com/brookdb/balletvinyl",
            text: "Code"
          }
        ],
        paragraph:
          "Ballet Vinyls is a conceptual brand that offers an immersive journey into the world of ballet through vinyl records. This project is a single-page scrolling story, brought to life using the power of React and the versatility of the GSAP framework. What makes it unique is the intricate web of multiple components within a complex hierarchy, resulting in a seamless user experience. To achieve this, I harnessed the magic of GSAP's ScrollTrigger and Flip plugins, enhancing the storytelling with elegant animations that respond to user interactions. To ensure a polished and fluid presentation, Lenis was employed to smoothen the transitions. In a world where comprehensive GSAP tutorials for React applications are scarce, I ventured to create my own implementation, making Ballet Vinyls a testament to my expertise in blending technology and artistry."
      }
    ]
  },
  {
    id: "grid-image-5",
    classNames: "image-square",
    title: "CONTACT",
    imgURL: "/images/beach1.JPG",
    items: [
      {
        form: true
      }
    ]
  }
];

export default content;
