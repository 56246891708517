//libraries
import React, { useRef, useContext } from "react";
import { gsap } from "gsap";
import {useGSAP} from "@gsap/react";
import { Flip } from "gsap/all";
import {
  getInitialPosition,
  map,
  getDistance
} from "../../Components/Tile/motionUtil";

//context
import gridContext from "../../Context/Grid";

//style
import "./styles.css";

//components
import Tile from "../../Components/Tile";
import Panel from "../../Components/Panel";

gsap.registerPlugin(Flip);

const Landing = ({ content }) => {
  const ContainerRef = useRef(null);
  const animationSettings = {
    duration: 1,
    ease: "expo.inOut"
  };

  const { 
    gridOpen,
    ctx
  } = useContext(gridContext);

  useGSAP(()=>{
    let container = ContainerRef.current;
    let letters = container.querySelectorAll(".landing-text");
    gridOpen ?
      letters.forEach((letter, index) => {
      //console.log(index);
      
      const { x, y } = getInitialPosition(letter);
      //console.log(`x: ${x}, y: ${y}`);
      const delay = map(getDistance(letter), 0, 1000, 0.4, 0);
      let initialConfig = {
        xPercent: x,
        yPercent: y,
        opacity: 0
      };
      gsap.set(letter, initialConfig, 0);
      //setAnimation(tile, initialConfig, 0);
      gsap.to(letter, {
        duration: animationSettings.duration,
        ease: "expo",
        xPercent: 0,
        yPercent: 0,
        delay: delay,
        opacity: 1,
        visibility: "visible"
      });
      //addAnimation(animation, 0.4);
      })
      :
      letters.forEach((letter, index) => {
      const { x, y } = getInitialPosition(letter);
      //console.log(`x: ${x}, y: ${y}`);
      const delay = map(getDistance(letter), 0, 1000, 0.4, 0);
      let animation = gsap.to(letter, {
        duration: animationSettings.duration,
        ease: animationSettings.ease,
        xPercent: x,
        yPercent: y,
        opacity: 0,
        delay: delay
      });

      ctx.add(animation, 0.2);
      ctx.add(() => {
        gsap.to(letter, {
          visibility: "hidden",
          delay: 1
        });
      });
      });

  }, {dependencies: [gridOpen], scope: ContainerRef});
  return (

  <>
      <div className="grid--wrapper" ref={ContainerRef}>
        <Tile content={content[0]} />
        <span className="landing-text" id="title-text-1">BROOK</span>
        <Tile content={content[1]} />
        <span className="landing-text" id="title-text-2">DABA</span>
        <Tile content={content[2]} />
        <span className="landing-text" id="title-text-3">PORTFOLIO</span>
        <Tile content={content[3]} />
      </div>
      <Panel/>
   </>
  );
};

export default Landing;
