import React, { useRef, useState, useContext, useLayoutEffect } from "react";
import { gsap } from "gsap";
import {useGSAP} from "@gsap/react";
import { Flip } from "gsap/all";
import gridContext from "../../Context/Grid";

//style
import "./styles.css";

//component
import Contact from "../Form";
import About from "./About";

gsap.registerPlugin(Flip);

const Panel = () => {
  const [panelID, setPanelID] = useState(null);
  const { panelContent, gridOpen, activeTile } = useContext(
    gridContext
  );

  const PanelRef = useRef(null);
  //const tile = document.querySelector(".grid--wrapper");
  const { contextSafe } = useGSAP({scope: PanelRef});
  const animationSettings = {
    duration: 1,
    ease: "expo.inOut"
  };
  
  const openPanelGood = contextSafe((panel) => {
    const panelState = Flip.getState(panel);

    panelContent && setPanelID(`panel-${panelContent.id}`);
    panel.classList.add("open--panel");

    Flip.from(panelState, {
      duration: 1.75,
      delay: 0.8,
      ease: animationSettings.ease,
      onEnter: (elements) =>
        gsap.fromTo(
          elements,
          { opacity: 0, yPercent: 100 },
          { opacity: 1, yPercent: 0 }
        )
    });

  });

  const closePanel = contextSafe((panel) => {
    //console.log("close panel fired");
    const panelState = Flip.getState(panel);
    panel.classList.remove("open--panel");
    Flip.from(panelState, {
      duration: 0.75,
      ease: animationSettings.ease,
      absolute: true,
      onLeave: (elements) =>
        gsap.fromTo(
          elements,
          { opacity: 1, yPercent: 0 },
          { opacity: 0, yPercent: 100 }
        )
    });
  });

  useLayoutEffect(() => {
    if (activeTile != null) {
      const panel = PanelRef.current;
      !gridOpen ? openPanelGood(panel) : closePanel(panel);
    }
  }, [gridOpen,]);

  return (
    <div
      id={panelID && panelID}
      className="tile--details"
      ref={PanelRef}
    >
      

      <div className="details--wrapper">


        {panelContent &&
          panelContent.items.map((item, index) => (
            <div key={index} className="details--item">
              {item.about && <About  PanelRef={PanelRef}/>}
              {item.title && <h3>{item.title}</h3>}
              {item.subtitle && <h4>{item.subtitle}</h4>}
              {item.img && (
                <div
                  className={item.img.classNames}
                  style={{
                    backgroundImage: `url(${item.img.url})`
                  }}
                ></div>
              )}
              {item.links && (
                <div className="details--item--links">
                  {item.links.map((link, index) => (
                    <a key={index} target="_blank" rel="noreferrer" href={link.href}>
                      {link.text}
                    </a>
                  ))}
                </div>
              )}
              {item.form && <Contact gridOpen={gridOpen} />}

              <p>{item.paragraph}</p>
              {item.ul &&
                <ul>{item.ul.map((item, index) => <li key={index}>{item}</li>)}</ul>

              }


            </div>
          ))}
      </div>
    </div>
  );
};

export default Panel;
