import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './styles.css';

const Form = ({gridOpen}) => {
  const form = useRef();
  const [showSuccessMessage, setShowSuccessMessage ] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
            // Display a success message and clear the form
            form.current.reset();
            setShowSuccessMessage(true);
            //console.log(result.text);

        },
        (error) => {
            console.log(error.text);
        }
      );
  };
  useEffect(()=>{
    gridOpen && setShowSuccessMessage(false);
  }, [gridOpen]);
  return (
    <div id="form-container">
        {showSuccessMessage ? (
        <div class="success-message">
            <h3>Message sent successfully!</h3>
            <p>Thank you for your message. We have received it and will get back to you as soon as possible.</p>
        </div>) :
        (<form className='cf' ref={form} onSubmit={sendEmail}>
          <input type='text' placeholder='Name' name='user_name' required />
          <input type='email' placeholder='Email address' name='user_email' required />
          <input type='text' placeholder='Subject' name='subject' required />
          <textarea name='message' type='text' placeholder='Message' required></textarea>
          <input type='submit' value='Submit' id='input-submit' />
        </form>
        )}
    </div>
  );
};

export default Form;