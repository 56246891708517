import React, {  useEffect, useRef, useContext, useLayoutEffect } from "react";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {useGSAP} from "@gsap/react";



import './styles.css';

gsap.registerPlugin(ScrollTrigger);

const About = ({ PanelRef }) => {
  const imageRef = useRef(null);
  useGSAP(()=>{
    console.log(PanelRef.current)
    const image = imageRef.current;
    const zoomIn = gsap.timeline({
      scrollTrigger: {
        scroller: PanelRef.current,
        trigger: image,//"#grid-image-1",
        start: 'top+=130 center', // When the top of the image hits the center of the viewport
        end: 'top+=303px center', // When the bottom of the image hits the center of the viewport
        markers: false, // Remove this in production
        scrub: 1, // Smooth animation
      },
    });

    zoomIn.to(image, {
        scale: 1.25,
    });
  });

  return (
    <div className="about-section-wrapper">
        <div id="about-section1">
            <div className="section-1-left">
                <h3>Welcome to my digital playground!</h3>
                <h4>{"Hey there! I'm Brook, and this is where I showcase my journey as a Full Stack Developer. Feel free to explore and see what I've been cooking up!"}</h4>
            </div>
            <div className="section-1-right">
                <div
                  className="profile-img md"
                  ref={imageRef}
                  style={{
                    backgroundImage: `url(/images/profile1.jpg)`
                  }}
                ></div>
            </div>
        </div>
        <div className="about-section2">
            <div className="about-section">
                <h4>What I Do</h4>
                <p>{"I specialize in building web applications from the ground up, focusing primarily on Django for backend and React for frontend development. Whether it's creating intuitive UIs or optimizing database queries, I thrive on solving problems and delivering top-notch solutions."}</p>
            </div>
            <div className="about-section">
                <h4>{ "What I'm Looking For"}</h4>
                <p>{"I'm currently open to fulltime opportunities, freelance projects, and exciting collaborations. I have a particular interest in web development and would love to contribute my skills to a dynamic team that's pushing the boundaries."}</p>
            </div>
            <div className="about-section">
                <h4>A Bit About Me</h4>
                <p>{"When I'm not lost in code or teaching Computer Science to the brilliant minds of Baltimore City Public Schools, you'll find me in at the Bun Shop on Read st or at a ballet studio in Silver Spring."}</p>
            </div>
        </div>
    </div>
  );
};

export default About;
